@import "fuelux-core.less";
.fuelux {

	.placard {
		display: inline-block;
		position: relative;

		&[data-ellipsis="true"] {
			&.showing {
				input.placard-field {
					overflow: visible;
					text-overflow: clip;
					white-space: normal;
				}
			}

			input.placard-field {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&::-ms-clear {
					display:none;
				}
			}

			textarea.placard-field {
				&[readonly] {
					overflow: hidden;
				}
			}
		}

		&.showing {
			.placard-footer,
			.placard-header,
			.placard-popup {
				display: block;
				z-index: 1;
			}

			input.placard-field, textarea.placard-field {
				background: @true-white;
				border: 1px solid @gray80;
				box-shadow: none;
				position: relative;
				z-index: 1;
			}
		}

		input.placard-field, textarea.placard-field {
			resize: none;

			&[readonly] {
				background: @true-white;
				cursor: auto;

				&.glass {
					background: none;

					&:hover {
						background: @info-background;
						cursor: pointer;
					}
				}
			}

			&:focus {
				border: 1px solid @gray80;
				box-shadow: none;
			}
		}

		&-cancel {
			font-size: 12px;
			margin-right: 4px;
			vertical-align: middle;
		}

		&-footer,
		&-header {
			display: none;
			left: 0;
			line-height: 1;
			position: absolute;
			right: 0;
		}

		&-footer {
			padding: 4px 0 8px 0;
			text-align: right;
			top: 100%;
		}

		&-header {
			bottom: 100%;
			padding: 8px 0 4px 0;

			h1, h2, h3, h4, h5, h6 {
				margin: 0;
			}
		}

		&-popup {
			background: @info-background;
			background-clip: padding-box;
			border: 1px solid @gray80;
			border-radius: 4px;
			bottom: -8px;
			box-shadow: 0 0 0 1px @true-white inset;
			display: none;
			left: -8px;
			position: absolute;
			right: -8px;
			top: -8px;
		}

		.glass {
			background: transparent;
			border: 1px solid @true-white;
			box-shadow: none;

			&:hover {
				background: @info-background;
				border-color: @focus-color;
				cursor: pointer;

				&[disabled] {
					background: transparent;
					border-color: @true-white;
					cursor: not-allowed;
				}
			}

			&:focus {
				background: @true-white;
				border-color: @focus-color;
				box-shadow: inset 0 1px 1px fade(@true-black, 75%), 0 0 8px fade(@focus-color, 60%);
				cursor: auto;

				&[disabled] {
					background: transparent;
					border-color: @true-white;
					cursor: not-allowed;
				}
			}

			&[disabled] {
				cursor: not-allowed;
			}
		}
	}

}