@import "fuelux-core.less";
.fuelux {

	.checkbox {

		&.highlight {

			// padding: 4px;

			& + .checkbox.highlight {
				margin-top: -5px;
			}

			& label.checkbox-custom {
				padding: 4px 4px 4px 24px;
				//margin-left: -8px;
			}

			&.checked label.checkbox-custom, label.checked.checkbox-custom {
				background: @gray90;
				border-radius: @base-border-radius;
			}
		}

		/* for keyboard tabbing */
		input[type="checkbox"]:focus + .checkbox-label,
		input[type="checkbox"]:hover + .checkbox-label,
		.checkbox-label:hover
		 {
			color: @gray60;
		}

		input[type="checkbox"]:disabled:focus + .checkbox-label,
		input[type="checkbox"]:disabled:hover + .checkbox-label,
		&.disabled label:hover
		 {
			color: inherit;
		}

	}

	.form-horizontal .checkbox-inline {
		padding-top: 0;
	}

	.input-group-addon.checkbox-custom.checkbox-inline:before {
		left: 11px;
		top: 9px;
	}

	.checkbox-custom {
		position: relative;

		&:before {
			#fuelux > .icon-base-styles("Glyphicons Halflings");
			border: 1px solid @gray67;
			border-radius: @base-border-radius;
			color: @true-white;
			content: " ";
			font-size: 9px;
			height: 14px;
			left: 0px;
			padding: 1px 0 0 1px;
			position: absolute;
			top: 3px;
			width: 14px;
		}

		/* for keyboard tabbing */
		&:active:before, &:focus:before {
			color: @focus-color;
			cursor: pointer;
			box-shadow: inset 0px 0px 2px 1px @focus-color, 0px 0px 5px 0px @focus-color;
		}

		&.highlight {
			&:before {
				left: 4px;
				top: 6px;
			}
		}

		&.checked {
			&:before {
				background: @checkbox-primary-color;
				border-color: @checkbox-primary-color;
				content: "\e013";
			}
		}

		&.disabled {
			cursor: not-allowed;
			opacity: .65;

			&:before
			{
				cursor: not-allowed;
				opacity: .65;
			}
		}

		&.checkbox-inline {
			&:before {
				left: 0;
				top: 3px;
			}

			&.highlight {
				padding: 4px 4px 4px 24px;
				left: -4px;

				&:before {
					left: 4px;
					top: 7px;
				}

				&.checked {
					background: @gray90;
					border-radius: @base-border-radius;
				}
			}
		}


		/* for keyboard tabbing */
		input[type="checkbox"]:focus + .checkbox-label {
			color: @gray60;
		}

	}

	label.checkbox-custom.checkbox-inline {
		padding-left: 20px;
	}

}
