@import "fuelux-core.less";
.fuelux {

	.tree {
		border: 1px solid @gray60;
		border-radius: 4px 4px 4px 4px;
		padding: 10px 15px 0 15px;
		overflow-x: auto;
		overflow-y: auto;
		position: relative;
		list-style: none;

		*:focus {
			outline: none;
		}

		button:focus {
			color: @tree-hover-text;
		}

		ul {
			padding-left: 0;
			margin-top: 0;
			margin-bottom: 0;
			list-style: none;
		}

		li {
			margin: 0;
			margin-top: 5px;
			margin-bottom: 5px;
		}

		.tree-loader {
			// make even with tree-branch-children
			margin-left: 45px;
		}

		.tree-open > .tree-branch-header .glyphicon-play {
			transform:rotate(90deg);
			-ms-transform:rotate(90deg);
			-webkit-transform:rotate(90deg);
			position: relative;
		}

		[data-children=false] > .tree-branch-header .icon-caret:before {
			content: '\00a0'; //space
		}

		.tree-branch {

			.tree-branch-header {
				position: relative;
				border-radius: 6px;
				white-space: nowrap;

				.tree-branch-name:hover {
					color: @tree-hover-text;
				}

				.glyphicon-play {
					font-size: 10px;
					padding-right: 5px;
					padding-left: 7px;

					&:before {
						position: relative;
						top: -2px;
					}

				}

				.tree-branch-name {
					white-space: nowrap;
					border-radius: 6px;
					background-color: transparent;
					border: 0;
				}

				label {
					font-weight: normal;
					padding-left: 3px;
					margin-bottom: 0;
					cursor: pointer;
				}

			}

			.tree-branch-children {
				margin-left: 14px;
			}
			&[haschildren='false'], &[data-has-children='false'] {
				.icon-caret {
					visibility: hidden;
				}
			}

		}

		.tree-item {
			white-space: nowrap;
			position: relative;
			cursor: pointer;
			border-radius: 6px;
			margin-left: 26px;

			.tree-item-name {
				white-space: nowrap;
				border-radius: 6px;
				background-color: transparent;
				border: 0;
			}

			.tree-item-name:hover {
				color: @tree-hover-text;
			}

			&.tree-selected .tree-item-name {
				background-color: @tree-select-background;
				color: @gray20;
			}

			label {
				font-weight: normal;
				padding-left: 7px;
				margin-bottom: 0;
				cursor: pointer;
			}

		}

		.icon-caret:hover + .tree-branch-name {
			color: @tree-hover-text;
		}

		// - - - - - - - - - - - - - -
		// folder selectable option
		// - - - - - - - - - - - - - -

		&.tree-folder-select {

			.tree-branch {

				.icon-caret {
					background-color: transparent;
					border: 0;
				}

				.icon-caret:hover {
					cursor: pointer;
					color: @tree-hover-text;
				}

				> .tree-branch-name {
					padding: 1px 5px 0 5px;
					border-radius: 6px;


					&.tree-selected {
						background-color: @tree-select-background;
					}
				}

			}

			.tree-branch.tree-selected > .tree-branch-header .tree-branch-name {
				background-color: @tree-select-background;

				&:hover {
					background-color: @tree-select-background;
				}
			}

		}

	}

}